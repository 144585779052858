<template>
  <v-card width="100%">
    <base-header-information
      :title="headerTitle"
      color="red accent-1"
      :showDate="false"
    >
      <template v-slot:description>
        <p class="ma-0">
          <strong>Importante:</strong> Não esqueça de informar seus pagamentos!
        </p>
        <p class="mt-1">
          Para que não se perca, atualize o status das suas guias assim que
          realizar seus pagamentos.
        </p>
      </template>
    </base-header-information>
    <v-container fluid>
      <v-data-table
        :disable-sort="$vuetify.breakpoint.smAndDown"
        class="elevation-0"
        :headers="headers"
        :items="scheduleFilter"
        :items-per-page.sync="options.itemsPerPage"
        hide-default-footer
      >
        <template v-slot:item.valid="{ item }">
          {{ item.valid | moment('DD/MM/YYYY') }}
        </template>
        <template v-slot:item.canvas="{ item }">
          {{ item.canvas | moment('MMMM/YYYY') }}
        </template>
        <template v-slot:item.value="{ item }">
          {{ item.value | currencyFromCents }}
        </template>
        <template v-slot:item.actions="{ item }" align="center">
          <v-btn
            v-if="item.receiptFileId === null"
            @click="dialogsGetTax(item)"
            text
            color="red accent-1"
          >
            Atualizar
            <v-icon>autorenew</v-icon>
          </v-btn>
          <v-btn v-else text> Aguardando </v-btn>
        </template>
        <template slot="no-data">
          <p class="text-center blue--text py-6 text-h5">
            <v-icon color="blue" medium>info</v-icon> Não há pendências.
          </p>
        </template>
      </v-data-table>
    </v-container>
    <dialog-unvalible-tax
      v-model="confirm"
      v-if="confirm"
      :unavailableTaxesProps="dataTaxes"
    ></dialog-unvalible-tax>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import dialogUnvalibleTax from '@src/views/app/Taxation/unavailableTax.vue'

export default {
  components: {
    'dialog-unvalible-tax': dialogUnvalibleTax,
  },
  data() {
    return {
      dataTaxes: {},
      confirm: false,
      headers: [
        {
          text: 'Prazo',
          align: 'left',
          value: 'valid',
          width: '10%',
        },
        {
          text: 'Período de Apuração',
          value: 'canvas',
          width: '10%',
        },
        {
          text: 'Categoria',
          value: 'categoryLabel',
          width: '10%',
        },
        {
          text: 'Nome',
          value: 'detail',
          width: '10%',
        },
        {
          text: 'Valor',
          value: 'value',
          width: '10%',
        },
        {
          text: 'Status',
          value: 'state',
          width: '10%',
        },
        {
          text: 'Ações',
          value: 'actions',
          align: 'center',
          width: '5%',
        },
      ],
      options: {
        itemsPerPage: -1,
      },
      headerTitle: `Pendências de meses anteriores`,
      headerDescription: `Confira abaixo se sua empresa possui alguma pendência de meses anteriores.
						Com o Simplificador, você não toma susto e resolve tudo rapidamente!
						*Importante: O status é atualizado para "pago" somente quando você informa ao sistema.`,
      filterDate: {
        isActive: true,
        date: new Date(),
      },
    }
  },
  computed: {
    ...mapState({
      tax: ({ taxations }) => taxations.tax,
    }),
    ...mapGetters({
      schedule: 'schedule/schedule',
    }),
    scheduleFilter() {
      return this.schedule.filter((item) => this.filterListByDate(item))
    },
  },
  methods: {
    ...mapActions({
      taxationsGet: 'taxations/getAll',
      taxGet: 'taxations/getTax',
      dialogsDetailTax: 'dialogs/changeDetailTax',
    }),
    filterListByDate(item) {
      return this.taxIsNotPaid(item.state)
    },
    taxIsNotPaid(status) {
      switch (status) {
        case 'Vencido':
          return true
          break
        default:
          return false
          break
      }
    },
    hasAdditionalServiceClient(tax) {
      return tax.additionalServiceClientId !== null
    },
    hoverTexAdditionalService(tax) {
      return this.hasAdditionalServiceClient(tax)
        ? 'Nova guia já solicitada acompanhe em Serviços Adicionais'
        : 'Solicitar nova Guia'
    },
    dialogsGetTax(item) {
      this.confirm = true
      this.dataTaxes = item
    },
    dialogsChangeTax(tax) {
      let status = true
      this.taxationsGet()
      this.dialogsDetailTax({ status, tax })
    },
  },
}
</script>

<style scoped></style>
