<template>
  <v-dialog v-model="localValue" width="550px">
    <v-card :class="$vuetify.breakpoint.smAndUp ? 'pa-5' : 'pa-1'">
      <v-card-title style=" word-break: break-word">
        <div>
          <v-btn small absolute top right icon @click="localValue = false">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <h4 style="color:#024059" class="ml-0 mb-4">
          Guia indisponível para download
          <v-icon color="#FF9800" v-if="$vuetify.breakpoint.smAndUp">
            info
          </v-icon>
        </h4>
      </v-card-title>
      <v-card-text style=" word-break: break-word">
        <v-row justify="start">
          <v-col cols="12">
            <p style="font-size:15px">
              A {{ unavailableTaxesProps.detail }} da competência de
              {{
                unavailableTaxesProps.dateReference | moment('MMMM/Y')
              }}
              encontra-se vencida desde o dia
              {{ unavailableTaxesProps.dueAt | moment('DD/MM/YYYY') }}.
            </p>
            <ul>
              <li class="mb-4" style="font-size:15px">
                Para realizar o download da segunda via solicite em
                <a
                  @click="goToService()"
                  style="color:#024059"
                  class="font-weight-bold"
                >
                  SERVIÇOS ADICIONIAIS.
                </a>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      valid: false,
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    unavailableTaxesProps: {
      type: Object,
    },
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    goToService() {
      this.$router.push({
        name: 'ShoppingList',
        query: { filter: 'Reemissão de guias de impostos e taxas' },
      })
    },
  },
}
</script>

<style scoped></style>
